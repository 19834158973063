import { InMemoryCache, makeVar } from '@apollo/client'
import { getUser } from './services/auth'

export const hasWindow = typeof window !== 'undefined'

const initialLoginState = {
  phoneNumber: '',
  countryId: 2,
}

export const isLoggedInVar = makeVar((hasWindow && !!getUser()?.user) || null)

export const selectedWalletVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('selectedWallet'))) ||
    null,
)

export const selectedContactVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('selectedContact'))) ||
    null,
)

export const selectedVoucherVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('selectedVoucher'))) ||
    null,
)
export const selectedSendVoucherVar = makeVar(
  (hasWindow &&
    JSON.parse(window.localStorage.getItem('selectedSendVoucher'))) ||
    null,
)

export const selectedPartnerVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('selectedPartner'))) ||
    null,
)

export const countrySelectedInfoVar = makeVar(
  (hasWindow &&
    JSON.parse(window.localStorage.getItem('countrySelectedInfo'))) ||
    null,
)

export const selectedTagVar = makeVar(
  (hasWindow && window.localStorage.getItem('selectedTag')) || null,
)

export const pushMoneyUserVar = makeVar(getUser() || null)

export const loginStateVar = makeVar(initialLoginState)

export const cache = new InMemoryCache({
  typePolicies: {
    Partner: {
      keyFields: ['id', 'aliasId'],
    },
    Query: {
      fields: {
        isLoggedIn: {
          read: () => {
            const isLoggedIn = isLoggedInVar()
            return isLoggedIn
          },
        },
        loginState: {
          read: () => {
            const loginState = loginStateVar()
            return loginState
          },
        },
        selectedTag: {
          read: () => selectedTagVar(),
        },
        selectedWallet: {
          read: () => {
            const selectedWallet = selectedWalletVar()
            return selectedWallet
          },
        },
        selectedContact: {
          read: () => selectedContactVar(),
        },
        selectedVoucher: {
          read: () => {
            const selectedVoucher = selectedVoucherVar()
            return selectedVoucher
          },
        },
        selectedSendVoucher: {
          read: () => {
            const selectedSendVoucher = selectedSendVoucherVar()
            return selectedSendVoucher
          },
        },
        selectedPartner: {
          read: () => {
            const selectedPartner = selectedPartnerVar()
            return selectedPartner
          },
        },
        pushMoneyUser: {
          read: () => {
            const pushMoneyUser = pushMoneyUserVar()
            return pushMoneyUser
          },
        },
        countrySelectedInfo: {
          read: () => {
            const countrySelectedInfo = countrySelectedInfoVar()
            return countrySelectedInfo
          },
        },
      },
    },
  },
})
