import { createTheme } from '@material-ui/core/styles'
// styles
import colors from './variables/colors'
import './fonts.css'
import typography from './variables/typography'

const {
  fontFamily,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
  paragraphSpacing,
} = typography

const properties = {
  palette: {
    surface: {
      surface_0: colors.OKY_SURFACE_0,
      surface_1: colors.OKY_SURFACE_1,
      surface_2: colors.OKY_SURFACE_2,
      surface_3: colors.OKY_SURFACE_3,
      surface_4: colors.OKY_SURFACE_4,
      surface_5: colors.OKY_SURFACE_5,
      surface_6: colors.OKY_SURFACE_6,
      surface_7: colors.OKY_SURFACE_7,
      surface_8: colors.OKY_SURFACE_8,
      surface_9: colors.OKY_SURFACE_9,
    },

    acqua: {
      acqua_onlight_0: colors.OKY_ACQUA_ONLIGHT_0,
      acqua_onlight_05: colors.OKY_ACQUA_ONLIGHT_05,
      acqua_onlight_1: colors.OKY_ACQUA_ONLIGHT_1,
      acqua_onlight_15: colors.OKY_ACQUA_ONLIGHT_15,
      acqua_onlight_2: colors.OKY_ACQUA_ONLIGHT_2,
      acqua_onlight_3: colors.OKY_ACQUA_ONLIGHT_3,
      acqua_onlight_4: colors.OKY_ACQUA_ONLIGHT_4,
      acqua_onlight_5: colors.OKY_ACQUA_ONLIGHT_5,
      acqua_onlight_6: colors.OKY_ACQUA_ONLIGHT_6,
      acqua_onlight_7: colors.OKY_ACQUA_ONLIGHT_7,
      acqua_onlight_8: colors.OKY_ACQUA_ONLIGHT_8,
      acqua_onlight_9: colors.OKY_ACQUA_ONLIGHT_9,
      acqua_onlight_10: colors.OKY_ACQUA_ONLIGHT_10,
      acqua_ondark_0: colors.OKY_ACQUA_ONLIGHT_10,
      acqua_ondark_05: colors.OKY_ACQUA_ONDARK_05,
      acqua_ondark_1: colors.OKY_ACQUA_ONDARK_1,
      acqua_ondark_15: colors.OKY_ACQUA_ONDARK_15,
      acqua_ondark_2: colors.OKY_ACQUA_ONDARK_2,
      acqua_ondark_3: colors.OKY_ACQUA_ONDARK_3,
      acqua_ondark_4: colors.OKY_ACQUA_ONDARK_4,
      acqua_ondark_5: colors.OKY_ACQUA_ONDARK_5,
      acqua_ondark_6: colors.OKY_ACQUA_ONDARK_6,
      acqua_ondark_7: colors.OKY_ACQUA_ONDARK_7,
      acqua_ondark_8: colors.OKY_ACQUA_ONDARK_8,
      acqua_ondark_9: colors.OKY_ACQUA_ONDARK_9,
      acqua_ondark_10: colors.OKY_ACQUA_ONLIGHT_0,
    },

    purple: {
      purple_onlight_0: colors.OKY_PURPLE_ONLIGHT_0,
      purple_onlight_05: colors.OKY_PURPLE_ONLIGHT_05,
      purple_onlight_1: colors.OKY_PURPLE_ONLIGHT_1,
      purple_onlight_15: colors.OKY_PURPLE_ONLIGHT_15,
      purple_onlight_2: colors.OKY_PURPLE_ONLIGHT_2,
      purple_onlight_3: colors.OKY_PURPLE_ONLIGHT_3,
      purple_onlight_4: colors.OKY_PURPLE_ONLIGHT_4,
      purple_onlight_5: colors.OKY_PURPLE_ONLIGHT_5,
      purple_onlight_6: colors.OKY_PURPLE_ONLIGHT_6,
      purple_onlight_7: colors.OKY_PURPLE_ONLIGHT_7,
      purple_onlight_8: colors.OKY_PURPLE_ONLIGHT_8,
      purple_onlight_9: colors.OKY_PURPLE_ONLIGHT_9,
      purple_onlight_10: colors.OKY_PURPLE_ONLIGHT_10,
      purple_ondark_0: colors.OKY_PURPLE_ONLIGHT_10,
      purple_ondark_05: colors.OKY_PURPLE_ONDARK_05,
      purple_ondark_1: colors.OKY_PURPLE_ONDARK_1,
      purple_ondark_15: colors.OKY_PURPLE_ONDARK_15,
      purple_ondark_2: colors.OKY_PURPLE_ONDARK_2,
      purple_ondark_3: colors.OKY_PURPLE_ONDARK_3,
      purple_ondark_4: colors.OKY_PURPLE_ONDARK_4,
      purple_ondark_5: colors.OKY_PURPLE_ONDARK_5,
      purple_ondark_6: colors.OKY_PURPLE_ONDARK_6,
      purple_ondark_7: colors.OKY_PURPLE_ONDARK_7,
      purple_ondark_8: colors.OKY_PURPLE_ONDARK_8,
      purple_ondark_9: colors.OKY_PURPLE_ONDARK_9,
      purple_ondark_10: colors.OKY_PURPLE_ONLIGHT_0,
    },

    yellow: {
      yellow_onlight_0: colors.OKY_YELLOW_ONLIGHT_0,
      yellow_onlight_05: colors.OKY_YELLOW_ONLIGHT_05,
      yellow_onlight_1: colors.OKY_YELLOW_ONLIGHT_1,
      yellow_onlight_15: colors.OKY_YELLOW_ONLIGHT_15,
      yellow_onlight_2: colors.OKY_YELLOW_ONLIGHT_2,
      yellow_onlight_3: colors.OKY_YELLOW_ONLIGHT_3,
      yellow_onlight_4: colors.OKY_YELLOW_ONLIGHT_4,
      yellow_onlight_5: colors.OKY_YELLOW_ONLIGHT_5,
      yellow_onlight_6: colors.OKY_YELLOW_ONLIGHT_6,
      yellow_onlight_7: colors.OKY_YELLOW_ONLIGHT_7,
      yellow_onlight_8: colors.OKY_YELLOW_ONLIGHT_8,
      yellow_onlight_9: colors.OKY_YELLOW_ONLIGHT_9,
      yellow_onlight_10: colors.OKY_ACQUA_ONLIGHT_10,
      yellow_ondark_0: colors.OKY_YELLOW_ONLIGHT_0,
      yellow_ondark_05: colors.OKY_YELLOW_ONDARK_05,
      yellow_ondark_1: colors.OKY_YELLOW_ONDARK_1,
      yellow_ondark_15: colors.OKY_YELLOW_ONDARK_15,
      yellow_ondark_2: colors.OKY_YELLOW_ONDARK_2,
      yellow_ondark_3: colors.OKY_YELLOW_ONDARK_3,
      yellow_ondark_4: colors.OKY_YELLOW_ONDARK_4,
      yellow_ondark_5: colors.OKY_YELLOW_ONDARK_5,
      yellow_ondark_6: colors.OKY_YELLOW_ONDARK_6,
      yellow_ondark_7: colors.OKY_YELLOW_ONDARK_7,
      yellow_ondark_8: colors.OKY_YELLOW_ONDARK_8,
      yellow_ondark_9: colors.OKY_YELLOW_ONDARK_9,
      yellow_ondark_10: colors.OKY_YELLOW_ONLIGHT_0,
    },

    primary: {
      hue: '269',
      base: colors.OKY_PURPLE_ONLIGHT_5,
      contrast_text: colors.OKY_SURFACE_0,
      text_dark: 'hsl(269, 90%, 43%)',
      main: 'hsl(269, 57%, 34%)',
      light: 'hsl(269, 47%, 74%)',
      dark: 'hsl(269, 81%, 14%)',
      background: 'hsla(269, 90%, 54%, 0.08)',
      selected: 'hsla(269, 90%, 54%, 0.08)',
    },

    secondary: {
      hue: '176',
      base: colors.OKY_ACQUA_ONLIGHT_5,
      contrast_text: colors.OKY_SURFACE_0,
      text_dark: 'hsl(176, 90%, 43%)',
      main: 'hsl(176, 76%, 39%)',
      light: 'hsl(176, 89%, 82%)',
      dark: 'hsl(176, 92%, 25%)',
      background: 'hsla(176, 90%, 54%, 0.08)',
      selected: 'hsla(176, 90%, 54%, 0.08)',
      border: 'hsla(176, 90%, 54%, 0.5)',
    },

    error: {
      contrast_text: colors.OKY_SURFACE_0,
      text_dark: colors.ERROR_TEXT_DARK,
      main: colors.ERROR_MAIN,
      light: colors.ERROR_LIGHT,
      dark: colors.ERROR_DARK,
      light_bg: colors.ERROR_LIGHT_BG,
    },

    warning: {
      contrast_text: colors.PRIMARY,
      text_dark: colors.WARNING_TEXT_DARK,
      main: colors.WARNING_MAIN,
      dark: colors.WARNING_DARK,
      light: colors.WARNING_LIGHT,
      light_bg: colors.WARNING_LIGHT_BG,
    },

    info: {
      contrast_text: colors.OKY_SURFACE_0,
      text_dark: colors.TEXT_DARK,
      main: colors.MAIN,
      dark: colors.DARK,
      light: colors.LIGHT,
      light_bg: colors.LIGHT_BG,
    },

    success: {
      contrast_text: colors.OKY_SURFACE_0,
      text_dark: colors.TEXT_DARK,
      main: colors.SUCCESS_MAIN,
      dark: colors.SUCCESS_DARK,
      light: colors.SUCCESS_LIGHT,
      light_bg: colors.SUCCESS_LIGHT_BG,
    },

    grey: {
      grey_50: colors.KNOB_INACTIVEKNOB,
      grey_100: colors.OKY_SURFACE_1,
      grey_200: colors.OKY_SURFACE_2,
      grey_300: colors.OKY_SURFACE_3,
      grey_400: colors.OKY_SURFACE_4,
      grey_500: colors.OKY_SURFACE_5,
      grey_600: colors.OKY_SURFACE_6,
      grey_700: colors.OKY_SURFACE_7,
      grey_800: colors.OKY_SURFACE_8,
      grey_900: colors.OKY_SURFACE_9,
      black: colors.DARK,
      white: colors.OKY_SURFACE_0,
      grey_A100: colors.GREY_A100,
      grey_A200: colors.GREY_A200,
      grey_A400: colors.OKY_SURFACE_7,
      grey_A700: colors.GREY_A700,
    },

    text: {
      primary: colors.PRIMARY,
      secondary: colors.SECONDARY,
      disabled: colors.DISABLED_HINT,
      hint: colors.DISABLED_HINT,
    },

    action: {
      main: colors.OKY_SURFACE_3,
      hover: colors.HOVER,
      selected: colors.SELECTED,
      disabled_bg: colors.DISABLED_BG_FOCUS,
      disabled: colors.DISABLED,
      focus: colors.DISABLED_BG_FOCUS,
    },

    background: {
      paper: colors.OKY_SURFACE_0,
      surface: colors.OKY_SURFACE_1,
    },

    greenBlue: {
      main: colors.GREEN_,
    },

    other: {
      border: colors.BORDER,
      white: colors.OKY_SURFACE_0,
      backdrop: colors.BACKDROP,
      snackbar: colors.SNACKBAR,
      active_rating: colors.ACTIVE_RATING,
      active_loving: colors.ACTIVE_LOVING,
      divider: colors.DISABLED_BG_FOCUS,
      knob_inactive: colors.KNOB_INACTIVEKNOB,
    },

    dark: colors.DARK,
    light: colors.OKY_SURFACE_0,
  },

  typography: {
    fontFamily,
    fontWeights,
    fontSizes,
    lineHeights,
    letterSpacing,
    paragraphSpacing,

    h1: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_0,
      fontSize: fontSizes.fontSizes_8,
      letterSpacing: letterSpacing.letterSpacing_0,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    h2: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_1,
      fontSize: fontSizes.fontSizes_7,
      letterSpacing: letterSpacing.letterSpacing_1,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    h3: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.fontMedium,
      lineHeight: lineHeights.lineHeights_2,
      fontSize: fontSizes.fontSizes_6,
      letterSpacing: letterSpacing.letterSpacing_2,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    h4: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_3,
      fontSize: fontSizes.fontSizes_5,
      letterSpacing: letterSpacing.letterSpacing_3,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    h5: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_4,
      fontSize: fontSizes.fontSizes_4,
      letterSpacing: letterSpacing.letterSpacing_2,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    h6: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_5,
      fontSize: fontSizes.fontSizes_0,
      letterSpacing: letterSpacing.letterSpacing_4,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    body1: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_6,
      fontSize: fontSizes.fontSizes_2,
      letterSpacing: letterSpacing.letterSpacing_4,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    body2: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelRegular,
      lineHeight: lineHeights.lineHeights_7,
      fontSize: fontSizes.fontSizes_2,
      letterSpacing: letterSpacing.letterSpacing_4,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    subtitle1: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_8,
      fontSize: fontSizes.fontSizes_2,
      letterSpacing: letterSpacing.letterSpacing_4,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    subtitle2: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_9,
      fontSize: fontSizes.fontSizes_1,
      letterSpacing: letterSpacing.letterSpacing_7,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    caption: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_10,
      fontSize: fontSizes.fontSizes_0,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    overline: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_11,
      fontSize: fontSizes.fontSizes_0,
      letterSpacing: letterSpacing.letterSpacing_7,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    button: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelBold,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_1,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    buttonLg: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelBold,
      lineHeight: lineHeights.lineHeights_12 + 2,
      fontSize: fontSizes.fontSizes_1 + 10,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    buttonSm: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelRegular,
      lineHeight: lineHeights.lineHeights_12 - 2,
      fontSize: fontSizes.fontSizes_1 - 1,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    code: {
      fontFamily: fontFamily.code,
      fontWeight: fontWeights.bodyBold,
      lineHeight: lineHeights.body,
      fontSize: fontSizes.sm,
    },
    icon3xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.icon3xl,
      fontSize: fontSizes.icon3xl,
    },
    icon2xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.icon2xl,
      fontSize: fontSizes.icon2xl,
    },
    iconXlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconXl,
      fontSize: fontSizes.iconXl,
    },
    iconXlargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconXl,
      fontSize: fontSizes.iconXl,
    },
    iconLarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconLg,
      fontSize: fontSizes.iconLg,
    },
    iconLargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconLg,
      fontSize: fontSizes.iconLg,
    },
    iconMedium: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconMd,
      fontSize: fontSizes.iconMd,
    },
    iconMediumSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconMd,
      fontSize: fontSizes.iconMd,
    },
    iconSmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconSm,
      fontSize: fontSizes.iconSm,
    },
    iconSmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconSm,
      fontSize: fontSizes.iconSm,
    },
    iconXsmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconXs,
      fontSize: fontSizes.iconXs,
    },
    iconXsmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconXs,
      fontSize: fontSizes.iconXs,
    },

    '@media (min-width:600px)': {
      h1: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_0,
        fontSize: fontSizes.fontSizes_8,
        letterSpacing: letterSpacing.letterSpacing_0,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      h2: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_1,
        fontSize: fontSizes.fontSizes_7,
        letterSpacing: letterSpacing.letterSpacing_1,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      h3: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.fontMedium,
        lineHeight: lineHeights.lineHeights_2,
        fontSize: fontSizes.fontSizes_6,
        letterSpacing: letterSpacing.letterSpacing_2,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      h4: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_3,
        fontSize: fontSizes.fontSizes_5,
        letterSpacing: letterSpacing.letterSpacing_3,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      h5: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_13,
        fontSize: fontSizes.fontSizes_3,
        letterSpacing: letterSpacing.letterSpacing_4,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      h6: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_5,
        fontSize: fontSizes.fontSizes_3,
        letterSpacing: letterSpacing.letterSpacing_4,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      body1: {
        fontFamily: fontFamily.body,
        fontWeight: fontWeights.bodyRegular,
        lineHeight: lineHeights.lineHeights_6,
        fontSize: fontSizes.fontSizes_2,
        letterSpacing: letterSpacing.letterSpacing_4,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      body2: {
        fontFamily: fontFamily.label,
        fontWeight: fontWeights.labelRegular,
        lineHeight: lineHeights.lineHeights_7,
        fontSize: fontSizes.fontSizes_2,
        letterSpacing: letterSpacing.letterSpacing_4,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      subtitle1: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_8,
        fontSize: fontSizes.fontSizes_2,
        letterSpacing: letterSpacing.letterSpacing_4,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      subtitle2: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeights.headingBold,
        lineHeight: lineHeights.lineHeights_9,
        fontSize: fontSizes.fontSizes_1,
        letterSpacing: letterSpacing.letterSpacing_7,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      caption: {
        fontFamily: fontFamily.body,
        fontWeight: fontWeights.bodyRegular,
        lineHeight: lineHeights.lineHeights_10,
        fontSize: fontSizes.fontSizes_0,
        letterSpacing: letterSpacing.letterSpacing_6,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      overline: {
        fontFamily: fontFamily.body,
        fontWeight: fontWeights.bodyRegular,
        lineHeight: lineHeights.lineHeights_11,
        fontSize: fontSizes.fontSizes_0,
        letterSpacing: letterSpacing.letterSpacing_7,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      button: {
        fontFamily: fontFamily.label,
        fontWeight: fontWeights.labelBold,
        lineHeight: lineHeights.lineHeights_12,
        fontSize: fontSizes.fontSizes_1,
        letterSpacing: letterSpacing.letterSpacing_6,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      buttonLg: {
        fontFamily: fontFamily.label,
        fontWeight: fontWeights.labelBold,
        lineHeight: lineHeights.lineHeights_12 + 2,
        fontSize: fontSizes.fontSizes_1 + 10,
        letterSpacing: letterSpacing.letterSpacing_6,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      buttonSm: {
        fontFamily: fontFamily.label,
        fontWeight: fontWeights.labelRegular,
        lineHeight: lineHeights.lineHeights_12 - 2,
        fontSize: fontSizes.fontSizes_1 - 1,
        letterSpacing: letterSpacing.letterSpacing_6,
        paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
      },
      code: {
        fontFamily: fontFamily.code,
        fontWeight: fontWeights.bodyBold,
        lineHeight: lineHeights.body,
        fontSize: fontSizes.sm,
      },
      icon3xlarge: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.icon3xl,
        fontSize: fontSizes.icon3xl,
      },
      icon2xlarge: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.icon2xl,
        fontSize: fontSizes.icon2xl,
      },
      iconXlarge: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.iconXl,
        fontSize: fontSizes.iconXl,
      },
      iconXlargeSolid: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconSolid,
        lineHeight: fontSizes.iconXl,
        fontSize: fontSizes.iconXl,
      },
      iconLarge: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.iconLg,
        fontSize: fontSizes.iconLg,
      },
      iconLargeSolid: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconSolid,
        lineHeight: fontSizes.iconLg,
        fontSize: fontSizes.iconLg,
      },
      iconMedium: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.iconMd,
        fontSize: fontSizes.iconMd,
      },
      iconMediumSolid: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconSolid,
        lineHeight: fontSizes.iconMd,
        fontSize: fontSizes.iconMd,
      },
      iconSmall: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.iconSm,
        fontSize: fontSizes.iconSm,
      },
      iconSmallSolid: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconSolid,
        lineHeight: fontSizes.iconSm,
        fontSize: fontSizes.iconSm,
      },
      iconXsmall: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconDefault,
        lineHeight: fontSizes.iconXs,
        fontSize: fontSizes.iconXs,
      },
      iconXsmallSolid: {
        fontFamily: fontFamily.icon,
        fontWeight: fontWeights.iconSolid,
        lineHeight: fontSizes.iconXs,
        fontSize: fontSizes.iconXs,
      },
    },
  },
}

const theme = createTheme(properties)

export default theme
