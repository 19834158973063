export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () => {
  const pushMoneyUser = isBrowser()
    ? window.localStorage.getItem('pushMoneyUser')
    : null
  if (isBrowser() && pushMoneyUser) {
    if (JSON.parse(pushMoneyUser)?.token) {
      return JSON.parse(pushMoneyUser)
    } else {
      return null
    }
  }
}

export const setUser = authReponse =>
  window.localStorage.setItem('pushMoneyUser', JSON.stringify(authReponse))

export const isLoggedIn = () => {
  const user = getUser()
  return !!user?.token
}

export const logout = callback => {
  setUser(null)
  callback()
}
