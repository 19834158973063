import * as Sentry from "@sentry/gatsby";

Sentry.init({
  integrations: [
    new Sentry.BrowserTracing({
        tracePropagationTargets: [
            'graphql.okyapp.com',
            'qa-graphql-backend.okyapp.com',
            'dev-co.okyapp.com',
            'co.okyapp.com',
            /^\//,
        ],
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.SENTRY_ENABLED === 'TRUE',
  dsn: process.env.SENTRY_DSN_KEY,
  environment: process.env.SENTRY_ENVIRONMENT,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_SAMPLE_TRACE),
  replaysSessionSampleRate: parseFloat(process.env.SENTRY_REPLAYSSESSION),
  replaysOnErrorSampleRate: parseFloat(process.env.SENTRY_REPLAYSON),
  debug: process.env.SENTRY_DEBUG === 'TRUE',
})