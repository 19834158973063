import React from 'react'
import theme from './theme/index'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'

const wrapRootElement = ({ element }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </MuiThemeProvider>
  )
}

export default wrapRootElement
