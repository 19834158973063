export default {
  fontFamily: {
    heading: 'Lato',
    body: 'Lato',
    label: 'Lato',
    code: 'Inconsolata',
    icon: 'Font Awesome 5 Pro',
    '@media (min-width:600px)': {
      heading: 'Lato',
      body: 'Lato',
      label: 'Lato',
      code: 'Inconsolata',
      icon: 'Font Awesome 5 Pro',
    },
  },

  fontWeights: {
    headingRegular: '400',
    headingBold: '700',
    bodyRegular: '400',
    bodyBold: '700',
    labelRegular: '400',
    labelBold: '700',
    codeRegular: '400',
    codeBold: '700',
    iconLight: '300',
    iconRegular: '400',
    iconSolid: '500',
    iconDefault: '300',
  },

  fontSizes: {
    fontSizes_0: 12,
    fontSizes_1: 14,
    fontSizes_2: 16,
    fontSizes_3: 18,
    fontSizes_4: 24,
    fontSizes_5: 34,
    fontSizes_6: 48,
    fontSizes_7: 60,
    fontSizes_8: 96,
    xxxl: 84.5,
    xxl: 42.25,
    xl: 28,
    lg: 21.25,
    md: 18.5,
    sm: 16,
    xs: 14,
    xxs: 12,
    icon3xl: 360,
    icon2xl: 180,
    iconXl: 42.25 * 2,
    iconLg: 48,
    iconMd: 24,
    iconSm: 16,
    iconXs: 12,
  },

  lineHeights: {
    lineHeights_0: '112.03%',
    lineHeights_1: '120%',
    lineHeights_2: '116.7%',
    lineHeights_3: '123.5%',
    lineHeights_4: '133.4%',
    lineHeights_5: '160%',
    lineHeights_6: '150%',
    lineHeights_7: '143%',
    lineHeights_8: '175%',
    lineHeights_9: '157%',
    lineHeights_10: '166%',
    lineHeights_11: '266%',
    lineHeights_13: '100%',
    lineHeights_12: '24px',
  },

  letterSpacing: {
    letterSpacing_0: -1.5,
    letterSpacing_1: -0.5,
    letterSpacing_2: 0,
    letterSpacing_3: 0.25,
    letterSpacing_4: 0.15,
    letterSpacing_5: 0.1,
    letterSpacing_6: 0.4,
    letterSpacing_7: 1,
  },

  paragraphSpacing: {
    paragraphSpacing_0: 0,
  },
}
