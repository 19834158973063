import React from 'react'
import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getUser } from '../services/auth'
import { cache } from '../cache'

const httpLink = createHttpLink({
  uri: process.env.MIDDLE_END_URL,
})

const authLink = setContext((_, { headers }) => {
  const hasToken = !!getUser()?.token
  return {
    headers: {
      ...headers,
      authorization: hasToken ? `Bearer ${getUser().token}` : '',
    },
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  name: 'push-money',
})

function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
export default Apollo
